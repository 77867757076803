import { useColorScheme } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import { authActions } from "_store";
import React, { useEffect, useState } from "react";
import { Triangle } from "react-loader-spinner";
import GoogleIcon from "../_components/GoogleIcon";
import { PreLoginLayout } from "../_components/PreLoginLayout";
import { history } from "../_helpers";
import { getsupabaseClient } from "../_helpers/supabase-backend";
import Logo from "assets/images/new_logo.png";


import userApi from 'api/user';
import { deleteAuthorizationHeader, setAuthorizationHeader } from "api/chatClient";
import {message} from "antd";
import creditApi from "../api/credits";
import {setTotalCredits} from "../_store/actions";
import { useDispatch, useSelector } from "react-redux";
export { Login };

function Login() {
  const dispatch = useDispatch();
  let showLoader = localStorage.getItem("showLoader");
  if (showLoader === undefined || showLoader === null) {
    showLoader = false;
    localStorage.setItem("showLoader", false);
  }
  const [isActive, setIsActive] = useState(true);
  const [loader, setLoader] = React.useState(showLoader === "true" ? true : false);
  const { mode, setMode } = useColorScheme();
  const { supabase } = getsupabaseClient();
  const [session, setSession] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const storedTotalCredits = useSelector((state) => state.userCredits.totalCredits);
  const [totalCredits, setTotalCredits] = useState(storedTotalCredits);

  useEffect(() => {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      if (session) {
        const createUser = () => {
          const requestBody = {
            user_id: session?.user?.id,
            email_id: session?.user?.email,
            active: true
          }
          const userResponse = userApi.createUser(requestBody)
        }
        const accessToken = session.access_token
        setAuthorizationHeader(accessToken)
        const getUserResponse = await userApi.getUser({ email: session.user.email })
        
          const fetchTotalCredits = async () => {
            try {
              const totalCreditsResponse = await creditApi.getTotalCredits(session.user.email);
              console.log(totalCreditsResponse?.data[0]?.balance,"totalCredits");
              setTotalCredits(totalCreditsResponse?.data[0]?.balance);
              dispatch(setTotalCredits(totalCreditsResponse?.data[0]?.balance));
            
            } catch (error) {
              console.error("Error fetching total credits:", error);
            }
          };

        if (getUserResponse.data.data[0]?.active === true) {
          createUser();
          setSession(session);
          if(storedTotalCredits==undefined){
            fetchTotalCredits();
          }
          dispatch(authActions.setAuth(session.user));

        }
        else if(getUserResponse.data.data[0]?.active === false){
          dispatch(authActions.logout());
          dispatch(authActions.setAuth(null));
          messageApi.warning("Your Account is Locked. Please Contact Admin to Login");
          localStorage.removeItem("auth");
          localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY);
          deleteAuthorizationHeader();
          localStorage.removeItem("showLoader", false);
          setLoader(false);
        }
        else if (getUserResponse.data.data.length <= 0 && process.env.REACT_APP_ENV === "prod") {
          createUser();
          localStorage.setItem("auth", JSON.stringify(session.user));
          const { from } = history.location.state || { from: { pathname: "/" } };
          history.navigate(from);
          localStorage.setItem("showLoader", false);
          setLoader(false);
        }
        else {
          dispatch(authActions.logout());
          dispatch(authActions.setAuth(null));
          messageApi.warning("Please Contact Admin to Login");
          localStorage.removeItem("auth");
          localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY);
          deleteAuthorizationHeader();
          localStorage.removeItem("showLoader", false);
          setLoader(false);
        }
      } else {
        setLoader(false)
      }
      setMode("light");
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    subscription.unsubscribe();

  }, [session]);

  return (
    <>

      {contextHolder}
      {loader ? (
        <Box sx={{ display: "flex", minHeight: "94dvh", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: "relative", alignItems: "center", justifyContent: "center" }}>
            <Triangle height="100" width="100" color="#6C5CE7" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={loader} />
          </Box>
        </Box>
      ) : (
        <PreLoginLayout>
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", gap: 25 }}>
            <img 
              src={Logo} 
              alt="" 
              style={{ height: "72px", width: "72px", cursor: "pointer" }} 
              onClick={() => window.location.href='https://brilo.ai'}
            />
            <Typography component="h1" fontSize="xl2" fontWeight="lg" level="body-sm" fontFamily="RF Dewi">
              Get Started
            </Typography>
            <Button
              variant="outlined"
              color="neutral"
              fullWidth
              startDecorator={<GoogleIcon />}
              onClick={async () => {
                localStorage.setItem("showLoader", true);
                setLoader(true);
                const { data, error } = await supabase.auth.signInWithOAuth({
                  provider: "google",
                });
              }}
              sx={{ width: "10vw", minWidth: "200px", borderColor: "#6741D9", borderRadius: 0 }}
            >
              Sign in with Google
            </Button>
            <Typography level="body-sm">
              By signing up, I agree <a href="https://www.brilo.ai/privacy">Privacy Policy</a> and <a href="https://www.brilo.ai/terms">Terms of Service</a>.
            </Typography>
          </div>
        </PreLoginLayout>
      )}
    </>
  );
}