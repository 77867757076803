import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Button,
	Card,
	Divider,
	Icon,
	IconButton,
	List,
	Paper,
	Tooltip,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { deleteAuthorizationHeader } from "api/chatClient";
import LogoSection from "layout/MainLayout/LogoSection";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import SettingsIcon from "../../../../../assets/custom_icons/Settings.svg";
import UpgradeIcon from "../../../../../assets/custom_icons/Upgrade.svg";
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";
import { DownOutlined } from "@ant-design/icons";
import { authActions } from "_store";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import MenuList from "@mui/material/MenuList";
import { Modal, ModalDialog } from "@mui/joy";
import {
	setTotalCredits,
} from "../../../../../_store/actions";
import { InputNumber, Spin } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import subscription from 'api/subscription';
import { setAuthorizationHeader } from "api/chatClient"
import creditApi from "../../../../../api/credits"
import "./index.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Refresh, RefreshOutlined } from "@mui/icons-material";

const NavGroup = ({ item, setLogoutLoader }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const [userName, setUserName] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userProfileUrl, setUserProfileUrl] = useState("");
	const navigate = useNavigate();
	const history = useNavigate();
	const showSetting = false;
	const showCredit = false;
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const [openLogoutModal, setOpenLogoutModal] = useState(false);
	const [confirmLogout, setConfirmLogout] = useState(false);
	const [openCreditModal, setOpenCreditModal] = useState(false);
	const [creditAmount, setCreditAmount] = useState();
	const storedTotalCredits = useSelector(
		(state) => state.userCredits.totalCredits
	);
	const [totalBalance, setTotalBalance] = useState();
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};
	const handleAddCreditsClick = () => {
		// Navigate to the desired component
		navigate("/settings/manage_plans", { state: "addFunds" });
	};
	// useEffect(() => {
	//   const authTokenObject = JSON.parse(
	//     localStorage.getItem(process.env.REACT_APP_STORAGE_KEY)
	//   );
	//   if (
	//     authTokenObject &&
	//     authTokenObject.user &&
	//     authTokenObject.user.user_metadata &&
	//     authTokenObject.user.user_metadata.avatar_url
	//   ) {
	//     setUserProfileUrl(authTokenObject?.user?.user_metadata?.avatar_url);
	//   } else {
	//     setUserProfileUrl("");
	//   }

	//   if (authTokenObject && authTokenObject.access_token) {
	//     const accessToken = authTokenObject.access_token;
	//     setAuthorizationHeader(accessToken);
	//   } else {
	//     if (history) {
	//       navigate("/account/login");
	//     }
	//   }
	// }, [localStorage.getItem(process.env.REACT_APP_STORAGE_KEY)]);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === "Escape") {
			setOpen(false);
		}
	}

	React.useEffect(() => {
		const storedJson = localStorage.getItem(
			`sb-${process.env.REACT_APP_SUPABASE_ID}-auth-token`
		);
		const storedObject = JSON.parse(storedJson);
		const userEmail = storedObject.user.email;

		const fetchTotalCredits = async () => {
			try {
				setAuthorizationHeader(storedObject.access_token);
				const totalCreditsResponse = await creditApi.getTotalCredits(userEmail);
				setTotalBalance(totalCreditsResponse?.data[0]?.balance);
				dispatch(setTotalCredits(totalCreditsResponse?.data[0]?.balance));
			} catch (error) {
				console.error("Error fetching total credits:", error);
			}
		};
		fetchTotalCredits();
	}, []);

	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);
	const items =
		item &&
		item.children &&
		item.children.map((menu) => {
			switch (menu.type) {
				case "collapse":
					return <NavCollapse key={menu.id} menu={menu} level={1} />;
				case "item":
					return (
						<NavItem
							key={menu.id}
							item={menu}
							level={1}
							navType="MENU"
							pathName={window.location.pathname}
						/>
					);
				default:
					return (
						<Typography key={menu.id} variant="h6" color="error" align="center">
							Menu Items Error
						</Typography>
					);
			}
		});

	useEffect(() => {
		if (confirmLogout) {
			setLogoutLoader(true);
			handleLogout();
		}
	}, [confirmLogout]);

	useEffect(() => {
		const authTokenObject = JSON.parse(
			localStorage.getItem(process.env.REACT_APP_STORAGE_KEY)
		);
		if (
			authTokenObject &&
			authTokenObject.user.user_metadata.email &&
			authTokenObject.user.user_metadata.name
		) {
			const userEmail = authTokenObject.user.user_metadata.email;
			const userName = authTokenObject.user.user_metadata.name;
			setUserEmail(userEmail);
			setUserName(userName);
			if (
				authTokenObject &&
				authTokenObject.user &&
				authTokenObject.user.user_metadata &&
				authTokenObject.user.user_metadata.avatar_url
			) {
				setUserProfileUrl(authTokenObject?.user?.user_metadata?.avatar_url);
			} else {
				setUserProfileUrl("");
			}
		} else {
			navigate("/account/login");
		}
	}, []);

	useEffect(() => { }, [userEmail, userName]);

	const handleLogout = () => {
		remmoveUserSession();
		navigate("/account/login");
	};

	const remmoveUserSession = () => {
		dispatch(authActions.logout());
		dispatch(authActions.setAuth(null));
		localStorage.removeItem("auth");
		localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY);
		deleteAuthorizationHeader();
	};

	return (
		<>
			<React.Fragment>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							height: "98vh",
							justifyContent: "space-between",
						}}
					>
						<Box>
							<Box sx={{ ml: 1 }}>
								<LogoSection />
							</Box>
							<Divider />
							<List
								subheader={
									item.title && (
										<Typography
											variant="caption"
											sx={{ ...theme.typography.menuCaption }}
											display="block"
											gutterBottom
										>
											{item.title}
											{item.caption && (
												<Typography
													variant="caption"
													sx={{ ...theme.typography.subMenuCaption }}
													display="block"
													gutterBottom
												>
													{item.caption}
												</Typography>
											)}
										</Typography>
									)
								}
							>
								{items}
							</List>
							{showSetting && (
								<>
									<Divider sx={{ mt: 0.25, mb: 1.25 }} />
									<Button>
										<ReactSVG
											src={SettingsIcon}
											style={{
												marginRight: "1.5vh",
												height: "20px",
												width: "20px",
											}}
										/>
										<Typography
											sx={theme.typography.body1}
											fontWeight={500}
											color="rgba(230, 234, 239, 1)"
										>
											{" "}
											Settings{" "}
										</Typography>{" "}
									</Button>
								</>
							)}
						</Box>
						<div>
							<Card
								variant="solid"
								color="primary"
								sx={{
									// minHeight: "12vh",
									// maxHeight: "15vh",
									background:
										"linear-gradient(90deg, rgba(58,43,196,1) 0%, rgba(167,54,214,1) 100%, rgba(123,29,253,1) 100%);",
									display: "flex",
									flexDirection: "column",
									zIndex: 5000,
									justifyContent: "flex-end",
								}}
							>
								<Box
									sx={{
										background:
											"linear-gradient(90deg, rgba(58,43,196,1) 0%, rgba(167,54,214,1) 100%, rgba(123,29,253,1) 100%);",
										display: "flex",
										flexDirection: "column",
										zIndex: 5000,
										justifyContent: "flex-end",
										marginY: "15px",
									}}
								>
									{/* <Typography sx={theme.typography.body1} fontWeight={500} color="rgba(252, 253, 253, 1)">
                    Your Total Credits
                  </Typography> */}
									{/* <div className={"mainProgressBarDiv"}>
                    <div className={"emptyProgressBar"} style={{ width: "100%", height: "2vh" }}>
                      <div
                        className={"fillingProgressBar"}
                        style={{
                          left: 32.4 - 100 + "%",
                          transition: "3s",
                          background: "linear-gradient(90deg, rgba(237,153,255,1) 0%, rgba(219,176,216,1) 0%, rgba(228,231,249,1) 100%",
                        }}
                      />
                    </div>
                  </div> */}

									<div style={{ flexDirection: "row",  display: "flex", alignItems: 'center', alignSelf: 'center'}}>
										{totalBalance !== undefined ? (
											<Typography
												sx={theme.typography.body1}
												fontWeight={500}
												color="rgba(252, 253, 253, 1)"
											>
												<div
													style={{
														display: "flex",
														justifyContent: "space-evenly",
													}}
												>
													<span>{`Balance:  $ ${parseFloat(storedTotalCredits).toFixed(
														2
													)} `}</span>
												</div>
												{/* <Tooltip title="this is tooltip" arrow>

                        <IconButton aria-label="Info" sx={{ color: "white" }} size="sm">
                          <HelpOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip> */}
											</Typography>
										) : (
											<>
												<div
													style={{
														display: "flex",
														justifyContent: "space-evenly",
														color: "white",
													}}
												>
													<Spin />
												</div>
											</>
										)}
										<IconButton
											onClick={async () => {
												const storedJson = localStorage.getItem(
													`sb-${process.env.REACT_APP_SUPABASE_ID}-auth-token`
												);
												const storedObject = JSON.parse(storedJson);
												const userEmail = storedObject.user.email;
												const totalCreditsResponse =
													await creditApi.getTotalCredits(userEmail);
												setTotalBalance(totalCreditsResponse?.data[0]?.balance);
												dispatch(
													setTotalCredits(totalCreditsResponse?.data[0]?.balance)
												);
											}}
										>
											<RefreshIcon
												sx={{
													color: "white",
												}}
											></RefreshIcon>
										</IconButton>
									</div>
								</Box>


								<Button
									variant="contained"
									size="sm"
									style={{
										backgroundColor: "white",
										color: "black",
										padding: "10px 0",
										"&:hover": {
											bgcolor: "white",
										},
									}}
									sx={theme.typography.body1}
									fontWeight={500}
									onClick={handleAddCreditsClick}
								>
									{/* <ReactSVG src={UpgradeIcon} style={{ marginRight: "5px", height: "1.5vh" }} />  */}
									Add Credits
								</Button>
							</Card>

							<div>
								<Button
									ref={anchorRef}
									id="composition-button"
									aria-controls={open ? "composition-menu" : undefined}
									aria-expanded={open ? "true" : undefined}
									aria-haspopup="true"
									onClick={handleToggle}
									sx={{
										width: "100%",
										display: "flex",
										justifyContent: "start",
										color: "rgba(230, 234, 239, 1)",
									}}
								>
									<Avatar
										src={`${userProfileUrl
											? userProfileUrl
											: "https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png"
											}`}
										sx={{
											border: "2px solid rgba(198, 203, 253, 1)",
											height: "25px",
											width: "25px",
											marginRight: 2,
										}}
									/>
									{userName}
								</Button>
								<Popper
									open={open}
									anchorEl={anchorRef.current}
									role={undefined}
									placement="bottom-start"
									transition
									disablePortal
									style={{ zIndex: "5001" }}
								>
									{({ TransitionProps, placement }) => (
										<Grow
											{...TransitionProps}
											style={{
												transformOrigin:
													placement === "bottom-start"
														? "left top"
														: "left bottom",
											}}
										>
											<Paper>
												<ClickAwayListener onClickAway={handleClose}>
													<MenuList
														autoFocusItem={open}
														id="composition-menu"
														aria-labelledby="composition-button"
														onKeyDown={handleListKeyDown}
													>
														<MenuItem onClick={handleClose} sx={{ width: 220 }}>
															{userEmail}
														</MenuItem>
														<Divider />
														<MenuItem onClick={() => setOpenLogoutModal(true)}>
															Logout
														</MenuItem>
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</div>
						</div>
					</Box>
				</Box >

				<Modal
					style={{ zIndex: 1010 }}
					open={openLogoutModal}
					onClose={() => {
						setOpenLogoutModal(false);
					}}
				>
					<ModalDialog
						aria-labelledby="basic-modal-dialog-title"
						aria-describedby="basic-modal-dialog-description"
						sx={{
							width: "25vw",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							gap: 2,
						}}
					>
						<Box>
							<Typography
								sx={{
									...theme.typography.body2,
									fontWeight: 400,
									color: "rgba(20, 20, 20, 1)",
								}}
							>
								Are you sure you want to logout ?
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								flexDirection: "row",
								width: "23vw",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									borderColor: "#6B5CE7",
									width: "11vw",
									...theme.typography.body1,
									fontWeight: 500,
									color: "rgba(20, 20, 20, 1)",
									"&:hover": {
										bgcolor: "white",
									},
								}}
								onClick={() => {
									setOpenLogoutModal(false);
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									setConfirmLogout(true);
									setOpenLogoutModal(false);
								}}
								sx={{
									backgroundColor: "#6B5CE7",
									width: "11vw",
									...theme.typography.body1,
									fontWeight: 500,
									color: "white",
									"&:hover": {
										bgcolor: "#6B5CE7",
									},
								}}
							>
								Confirm
							</Button>
						</Box>
					</ModalDialog>
				</Modal>
			</React.Fragment >
		</>
	);
};

NavGroup.propTypes = {
	item: PropTypes.object,
};

export default NavGroup;
