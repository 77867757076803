import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";
import MenuList from "./MenuList";
import LogoSection from "../LogoSection";
import { drawerWidth } from "../../../_helpers/constant";
import { Calculate } from "@mui/icons-material";

const Sidebar = ({ drawerOpen, drawerToggle, window, setLogoutLoader }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const drawer = (
    <>
        <PerfectScrollbar
          component="div"
          style={{
            height: '100%',
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <MenuList setLogoutLoader={setLogoutLoader}/>
        </PerfectScrollbar>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ width: drawerWidth}} aria-label="mailbox folders">
      <Drawer
        container={window !== undefined ? () => window.document.body : undefined}
        anchor="left"
        variant="persistent"
        open={true}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme?.palette?.common?.black,
            color: theme?.palette?.common?.white,
            borderRight: "none",
            zIndex: 1000,
          },
        }}
        ModalProps={{ keepMounted: true }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
