import PropTypes from "prop-types";
import React, { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { MENU_OPEN, SET_MENU } from "../../../../../_store/actions";
import config from "../../../../../config";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ReportsIcon from '../../../../../assets/custom_icons/Reports.svg'
import { ReactSVG } from "react-svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const NavItem = ({ item, level, navType, onClick, onUploadFile, pathName }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const selected = (item.pathName === pathName) || (pathName === '/' && item.pathName === '/knowledge-base') || (pathName.startsWith('/knowledge-base/') && item.pathName === '/knowledge-base') || (pathName.startsWith('/contact_lists/') && item.pathName === '/contact_lists') || (pathName.startsWith('/settings/') && item.pathName === '/settings') || (pathName.startsWith('/numbers/') && item.pathName === '/numbers');

  const Icon = item.icon;
  const itemIcon =
    item && item.icon ? (
      <FontAwesomeIcon icon={item.icon} style={{color: 'white'}}/>
    ) : (
      <FiberManualRecordIcon
        sx={{
          width: customization && customization.isOpen && customization.isOpen.findIndex((id) => id === item.id) > -1 ? 8 : 6,
          height: customization && customization.isOpen && customization.isOpen.findIndex((id) => id === item.id) > -1 ? 8 : 6,
        }}
        fontSize={level > 0 ? "inherit" : "medium"}
      />
    );

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef(function ListItemPropsComponent(props, ref) {
      return <Link ref={ref} {...props} to={`${config.basename}${item.url}`} target={itemTarget} />;
    }),
  };
  if (item && item.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }
  if (item && item.id === "loadChatflow") {
    listItemProps.component = "label";
  }

  const handleFileUpload = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!(evt && evt.target && evt.target.result)) {
        return;
      }
      const { result } = evt.target;
      onUploadFile(result);
    };
    reader.readAsText(file);
  };

  const itemHandler = (id) => {
    if (navType === "SETTINGS" && id !== "loadChatflow") {
      onClick(id);
    } else {
      dispatch({ type: MENU_OPEN, id });
      if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    }
  };

  useEffect(() => {
    if (navType === "MENU") {
      const currentIndex = document.location.pathname
        .toString()
        .split("/")
        .findIndex((id) => id === item.id);
      if (currentIndex > -1) {
        dispatch({ type: MENU_OPEN, id: item.id });
      }
      if (!document.location.pathname.toString().split("/")[1]) {
        itemHandler("chatflows");
      }
    }

  }, [navType]);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization && customization.borderRadius ? customization.borderRadius : "1"}px`,
        mb: 0.5,
        alignItems: "flex-start",
        backgroundColor: selected ? "rgba(103, 65, 217, 0.3) !important" : "transparent !important",
      }}
      selected={selected}
      onClick={() => itemHandler(item.id)}
    >
      {item.id === "loadChatflow" && <input type="file" hidden accept=".json" onChange={(e) => handleFileUpload(e)} />}
      <ListItemIcon
        sx={{
          my: "auto",
          minWidth: !(item && item.icon) ? 18 : 36,
          color: 'white',
        }}>
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant={customization && customization.isOpen && customization.isOpen.findIndex((id) => id === item.id) > -1 ? "h5" : "body1"}
            sx={theme.typography.body1}
            fontWeight={500}
            color="rgba(230, 234, 239, 1)"
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && <Chip color={item.chip.color} variant={item.chip.variant} size={item.chip.size} label={item.chip.label} avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>} />}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  navType: PropTypes.string,
  onClick: PropTypes.func,
  onUploadFile: PropTypes.func,
};

export default NavItem;
