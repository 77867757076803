import { lazy } from 'react'

import Loadable from '_components/ui-component/loading/Loadable'
import MinimalLayout from 'layout/MinimalLayout'

const AgentFull = Loadable(lazy(() => import('views/agent/AgentBot')))

const ChatbotRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/agent/:id',
            element: <AgentFull />
        },
    ]
}

export default ChatbotRoutes
