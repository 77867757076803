import chatClient from "./chatClient";

const crearteSubs = (body) => chatClient.post(`/stripe_payment`, body)

const billingPortal = (body) => chatClient.post(`/billing_portal`, body)

const getPaymentHistory = (body) => chatClient.get(`/transactions?page=${body.page ? body.page : 1}&perPageRecords=${body.perPageRecords ? body.perPageRecords : 10}`)
const phoneNumberPurchaseSubscription = (body)=> chatClient.post(`/purchasePhoneNumber`, body)

export default {
    crearteSubs,
    billingPortal,
    getPaymentHistory,
    phoneNumberPurchaseSubscription
}