import { useEffect } from "react";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import { authActions } from "_store";
import { useDispatch } from "react-redux";
import { PreLoginLayout } from "../_components/PreLoginLayout";
import { history } from "../_helpers";
import { deleteAuthorizationHeader } from "api/chatClient";
export { Logout };

function Logout() {
  const dispatch = useDispatch();
  const remmoveUserSession = () => {
    dispatch(authActions.logout());
    dispatch(authActions.setAuth(null));
    localStorage.removeItem("auth");
    localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY);
    deleteAuthorizationHeader();
  };

  useEffect(() => {
    remmoveUserSession();
  }, []);

  remmoveUserSession();
  return (
    <PreLoginLayout>
      <div>
        <Typography component="h1" fontSize="xl2" fontWeight="lg">
          You have successfully logged out!
        </Typography>
        <Typography level="body-sm" sx={{ my: 1, mb: 3 }}>
          Thank you for your time.
        </Typography>
      </div>

      <Button
        variant="outlined"
        fullWidth
        onClick={() => {
          history.navigate("/account/login");
        }}
      >
        Click here to Login
      </Button>
    </PreLoginLayout>
  );
}
