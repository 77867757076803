import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "_components/ui-component/loading/Loadable";

const DataSource = Loadable(lazy(() => import("views/datasource")));
const Agents = Loadable(lazy(() => import("views/agent")));
const Campaign = Loadable(lazy(() => import("views/campaign")));
const PhoneNumbers = Loadable(lazy(() => import("views/numbers")));
const Subscription = Loadable(lazy(() => import("views/subscription")));
const ContactList = Loadable(lazy(() => import("views/contactList")));
const Contacts = Loadable(lazy(() => import("views/contacts")));
const Recording = Loadable(lazy(() => import("views/recording")));
const Settings = Loadable(lazy(() => import("views/settings")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Agents />,
    },
    {
      path: "/agents",
      element: <Agents />,
    },
    {
      path: "/knowledge-base",
      element: <DataSource />,
    },
    {
      path: "/numbers",
      element: <PhoneNumbers />,
    },
    {
      path: "/numbers/purchase_phone_number",
      element: <Subscription />
    },
    {
      path: "/knowledge-base/:id",
      element: <DataSource />,
    },
    {
      path: "/campaign",
      element: <Campaign />,
    },
    {
      path: "/contact_lists",
      element: <ContactList />,
    },
    {
      path: "contact_lists/:id",
      element: <Contacts />,
    },
    {
      path: "call_logs",
      element: <Recording />,
    },
    {
      path: "/settings/webhook",
      element: <Settings />,
    },
    {
      path: "/settings/manage_plans",
      element: <Settings />,
    },
  ],
};

export default MainRoutes;
