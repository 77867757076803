import { authActions, store } from "../_store";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};

function request(method) {
  return (url, body) => {
    try {
      const requestOptions = {
        method,
        headers: authHeader(url),
      };
      if (body) {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
      return fetch(url, requestOptions)
        .then(handleResponse)
        .catch((error) => {
          return handleResponse({
            ok: false,
            status: 400,
            data: error,
          });
        });
    } catch (error) {
      return handleResponse({
        ok: false,
        status: 400,
        data: error,
      });
    }
  };
}

function authHeader(url) {
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

function authToken() {
  if (store) {
    return store?.getState().auth?.value?.token;
  } else {
    return null;
  }
}

async function handleResponse(response) {
  const contentType = response && response.headers && response.headers ? response.headers.get("content-type") : null;
  const isJson = contentType ? response.headers.get("content-type").includes("application/json") : null;
  const data = isJson ? await response.json() : response.data ? response.data : null;
  if (!response.ok) {
    if ([400, 401, 403].includes(response.status) && authToken()) {
      const logout = () => store?.dispatch(authActions.logout());
      logout();
    }
    let error = "-";
    if (response && response.message) {
      error = response.message;
    } else if (response && response.data) {
      error = response.data;
    } else if (data && data.message) {
      error = data.message;
    }
    return Promise.reject(error);
  }

  return data;
}
