import { fetchWrapper } from "_helpers";
import axios from "axios";
import { baseURLChat } from "../_helpers/constant"

const isMockData = false;
const authTokenObject = JSON.parse(localStorage.getItem(process.env.REACT_APP_STORAGE_KEY));
const accessToken = authTokenObject?.access_token;
const apiClient = isMockData
  ? fetchWrapper    
  : axios.create({
    baseURL: `${baseURLChat}/api/v1`,
    headers: {
      "Content-type": "application/json",
      // "Authorization": `Bearer ${accessToken}`                 
      },
  });

if (isMockData == false) {
  apiClient.interceptors.request.use(function (config) {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    if (username && password) {
      config.auth = {
        username,
        password,
      };
    }
    return config;
  });
}

export function setAuthorizationHeader(accessToken) {
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

export function deleteAuthorizationHeader() {
  delete apiClient.defaults.headers.common["Authorization"];
}

export default apiClient;
