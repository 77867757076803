export const gridSpacing = 3;
export const drawerWidth = 230;
export const appDrawerWidth = 320;
export const maxScroll = 100000;
export const baseURL =
  process.env.NODE_ENV === "production"
    ? window.location.origin
    : window.location.origin.replace(":3000", ":3001");
export const uiBaseURL = window.location.origin;
export const FLOWISE_CREDENTIAL_ID = "FLOWISE_CREDENTIAL_ID";
export const baseURLChat = process.env.REACT_APP_API_URL;
