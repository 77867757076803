import { configureStore,combineReducers } from "@reduxjs/toolkit";
import { alertReducer } from "./alert.slice";
import { authReducer } from "./auth.slice";
import { usersReducer } from "./users.slice";
import customizationReducer from './reducers/customizationReducer'
import canvasReducer from './reducers/canvasReducer'
import notifierReducer from './reducers/notifierReducer'
import dialogReducer from './reducers/dialogReducer'
import userCreditsReducer from "./reducers/userCreditsReducer";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
export * from "./alert.slice";
export * from "./auth.slice";
export * from "./users.slice";

const persister = "Free";

const persistConfig = {
  key: "root",
  storage: storageSession, // Use session storage
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    alert: alertReducer,
    auth: authReducer,
    users: usersReducer,
    customization: customizationReducer,
    canvas: canvasReducer,
    notifier: notifierReducer,
    dialog: dialogReducer,
    userCredits: userCreditsReducer,
  })
);
export var store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(store);
