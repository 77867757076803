import {
  faChartLine,
  faPhone,
  faHandshake,
  faFileCirclePlus,
  faClockRotateLeft,
  faWandMagicSparkles,
  faVolumeHigh,
  faCreditCard,
  faCog,
  faBullhorn,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  faChartLine,
  faPhone,
  faHandshake,
  faFileCirclePlus,
  faAddressBook,
  faVolumeHigh,
  faCreditCard,
  faCog,
  faBullhorn,
  faClockRotateLeft,
  faWandMagicSparkles,
};
const dashboard = {
  id: "dashboard",
  title: "",
  type: "group",
  children: [
    {
      id: "agents",
      title: "Agents",
      type: "item",
      url: "/agents",
      icon: icons.faWandMagicSparkles,
      breadcrumbs: true,
      pathName: "/agents",
    },
    {
      id: "numbers",
      title: "Phone Numbers",
      type: "item",
      url: "/numbers",
      icon: icons.faPhone,
      breadcrumbs: true,
      pathName: "/numbers",
    },
    {
      id: "campaign",
      title: "Campaign",
      type: "item",
      url: "/campaign",
      icon: icons.faBullhorn,
      breadcrumbs: true,
      pathName: "/campaign",
    },
    {
      id: "contact",
      title: "Contact List",
      type: "item",
      url: "/contact_lists",
      icon: icons.faAddressBook,
      breadcrumbs: true,
      pathName: "/contact_lists",
    },
    {
      id: "callLogs",
      title: "Call Logs",
      type: "item",
      url: "/call_logs",
      icon: icons.faClockRotateLeft,
      breadcrumbs: true,
      pathName: "/call_logs",
    },
    // {
    //   id: "payment",
    //   title: "Go Premium",
    //   type: "item",
    //   url: "/payment",
    //   icon: icons.faCreditCard,
    //   breadcrumbs: true,
    //   pathName: '/payment',
    // },
    {
      id: "settings",
      title: "Settings",
      type: "item",
      url: "/settings/manage_plans",
      icon: icons.faCog,
      breadcrumbs: true,
      pathName: "/settings",
    },
  ],
};

export default dashboard;
