export default function themeTypography(theme) {
  return {
    fontFamily: "Roboto, Arial, sans-serif",
    h6: {
      fontWeight: 500,
      color: theme.heading,
      fontSize: "0.75rem",
    },
    h5: {
      fontSize: "0.875rem",
      color: theme.heading,
      fontWeight: 500,
    },
    h4: {
      fontSize: "1rem",
      color: theme.heading,
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.25rem",
      color: theme.heading,
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.5rem",
      color: theme.heading,
      fontWeight: 700,
    },
    h1: {
      fontSize: "2.125rem",
      color: theme.heading,
      fontWeight: 700,
    },
    Heading1: {
      fontFamily: "RF Dewi Extended Semibold",
      fontSize: "24px",
      lineHeight: "31.2px",
    },
    Heading2: {
      fontFamily: "RF Dewi Extended Semibold",
      fontSize: "24px",
      lineHeight: "31.2px",
      fontWeight: 700,
    },
    Heading3: {
      fontFamily: "RF Dewi Extended Semibold",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
    },
    subtitle1: {
      fontFamily: "SF Pro Display Regular",
      lineHeight: "130%",
      fontSize: "24px",
      fontWeight: 700,
    },
    subtitle2: {
      fontFamily: "SF Pro Display Regular",
      lineHeight: "130%",
      fontSize: "20px",
      fontWeight: 700,
    },
    subtitle3: {
      fontFamily: "SF Pro Display Regular",
      lineHeight: "20.8px",
      fontSize: "16px",
      fontWeight: 700,
    },
    subtitle4: {
      fontFamily: "SF Pro Display Regular",
      lineHeight: "20.8px",
      fontSize: "17px",
      fontWeight: 500,
    },
    Body3: {
      fontFamily: "SF Pro Display",
      lineHeight: "150%",
      fontSize: "14px",
    },
    Body4: {
      fontFamily: "SF Pro Display",
      lineHeight: "150%",
      fontSize: "14px",
    },
    AddText: {
      fontFamily: "SF Pro Display Regular",
      lineHeight: "18px",
      fontSize: "12px",
      fontWeight: 700,
    },
    AddText2: {
      fontFamily: "SF Pro Display Regular",
      lineHeight: "15.6px",
      fontSize: "12px",
    },
    caption: {
      fontSize: "0.75rem",
      color: theme.darkTextSecondary,
      fontWeight: 400,
    },
    body1: {
      fontFamily: "SF Pro Display Regular",
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: 500,
    },
    body2: {
      fontWeight: 400,
      fontFamily: "SF Pro Display Regular",
      fontSize: "14px",
      lineHeight: "21px",
    },
    button: {
      textTransform: "capitalize",
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      "& > label": {
        top: 23,
        left: 0,
        color: theme.grey500,
        '&[data-shrink="false"]': {
          top: 5,
        },
      },
      "& > div > input": {
        padding: "30.5px 14px 11.5px !important",
      },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    mainContent: {
      backgroundColor: theme.background,
      width: "100%",
      minHeight: "calc(100vh - 75px)",
      flexGrow: 1,
      padding: "20px",
      marginTop: "75px",
      marginRight: "20px",
      borderRadius: `${theme?.customization?.borderRadius}px`,
    },
    menuCaption: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: theme.heading,
      padding: "6px",
      textTransform: "capitalize",
      marginTop: "10px",
    },
    subMenuCaption: {
      fontSize: "0.6875rem",
      fontWeight: 500,
      color: theme.darkTextSecondary,
      textTransform: "capitalize",
    },
    commonAvatar: {
      cursor: "pointer",
      borderRadius: "8px",
    },
    smallAvatar: {
      width: "22px",
      height: "22px",
      fontSize: "1rem",
    },
    mediumAvatar: {
      width: "34px",
      height: "34px",
      fontSize: "1.2rem",
    },
    largeAvatar: {
      width: "44px",
      height: "44px",
      fontSize: "1.5rem",
    },
  };
}
