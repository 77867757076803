import { createClient } from "@supabase/supabase-js";
import axios from "axios";
import { baseURL } from "../_helpers/constant";

export { getsupabaseClient, supabaseBackend, initSupabase };

let supabase = null;
const apiClient = axios.create({
  baseURL: `${baseURL}/api/v1`,
  headers: {
    "Content-type": "application/json",
  },
});

const dataSourceTable = "dataSource";
const agentTable = "agent";
const usersKey = "react-18-redux-registration-login-example-users";
let users = JSON.parse(localStorage.getItem(usersKey)) || [];

function getsupabaseClient() {
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
  const supabaseJWTToken = process.env.REACT_APP_SUPABASE_JWT_TOKEN;
  if (supabase === null) {
    supabase = createClient(supabaseUrl, supabaseKey);
  }
  return { supabaseUrl, supabaseKey, supabaseJWTToken, supabase };
}

function initSupabase() {
  const { supabase, supabaseJWTToken } = getsupabaseClient();
  return;
}

function supabaseBackend() {
  const { supabase, supabaseJWTToken } = getsupabaseClient();
  let realFetch = window.fetch;

  window.fetch = function (url, opts) {
    return new Promise(async (resolve, reject) => {
      await handleRoute();
      async function handleRoute() {
        switch (true) {
          case url.endsWith("/users/authenticate") && opts.method === "POST":
            return await authenticate();
          case url.endsWith("/users/register") && opts.method === "POST":
            return await register();
          case url.endsWith("/users/forgotPassword") && opts.method === "POST":
            return await forgotPassword();
          case url.endsWith("/users/logout") && opts.method === "POST":
            return await logout();
          case url.endsWith("/users") && opts.method === "GET":
            return await getUsers();
          case url.match(/\/users\/\d+$/) && opts.method === "GET":
            return await getUserById();
          case url.match(/\/users\/\d+$/) && opts.method === "PUT":
            return await updateUser();
          case url.match(/\/users\/\d+$/) && opts.method === "DELETE":
            return await deleteUser();
          case url.match("/dataSource") && opts.method === "POST":
            return await addDataSource();
          case url.endsWith("/dataSource") && opts.method === "GET":
            return await getDataSources();
          case url.match(/\/dataSource\/[a-f0-9-]+$/) && opts.method === "GET":
            return await getDataSourceById();
          case url.match(/\/dataSource\/[a-f0-9-]+$/) && opts.method === "PUT":
            return await updateDataSource();
          case url.match(/\/dataSource\/[a-f0-9-]+$/) &&
            opts.method === "DELETE":
            return await deleteDataSource();
          case url.match("/agent") && opts.method === "POST":
            return await addAgent();
          case url.endsWith("/agent") && opts.method === "GET":
            return await getAgents();
          case url.match(/\/agent\/[a-f0-9-]+$/) && opts.method === "GET":
            return await getAgentById();
          case url.match(/\/agent\/[a-f0-9-]+$/) && opts.method === "PUT":
            return await updateAgent();
          case url.match(/\/agent\/[a-f0-9-]+$/) && opts.method === "DELETE":
            return await deleteAgent();
          case url.endsWith("/nodes") && opts.method === "GET":
            return await getNodes();
          default:
            if (opts) {
              let reqInput = Object.assign(opts, { url: url });
              if (opts.body) {
                reqInput.data = opts.body;
                delete reqInput.body;
              }
              return apiClient
                .request(reqInput)
                .then((response) => ok({ data: response.data }))
                .catch((errorInfo) =>
                  error("error while calling API" + JSON.stringify(errorInfo))
                );
            } else {
              window.fetch = realFetch;
            }
        }
      }

      async function authenticate() {
        const { username, password } = body();
        const {
          user,
          session,
          error: errorInfo,
        } = await supabase.auth.signInWithPassword({
          email: username,
          password: password,
        });

        if (!user)
          return error(
            `${
              errorInfo && errorInfo.message
                ? errorInfo.message
                : "Username or password is incorrect"
            }`
          );

        return ok({
          ...basicDetails(user),
          token: supabaseJWTToken,
          user,
          session,
        });
      }

      async function logout() {
        const { error: errorInfo } = await supabase.auth.signOut();
        if (errorInfo) {
          return error(
            "Error while register new user wuth Username" +
              JSON.stringify(errorInfo)
          );
        }
        return errorInfo;
      }

      async function forgotPassword() {
        const { email } = body();
        const baseUrl = `${process.env.REACT_APP_API_URL}/users/update-password`;
        const { data, error } = await supabase.auth.resetPasswordForEmail(
          email,
          { redirectTo: baseUrl }
        );
        if (error && error.message) {
          alert(error.message);
        } else {
        }
      }
      async function register() {
        const { username, password, firstName, lastName } = body();

        let response = await supabase.auth.signUp({
          email: username,
          password: password,
          options: { data: { username, password, firstName, lastName } },
        });
        const { user, session, error: errorInfo } = response.data;
        if (errorInfo) {
          return error(
            "Error while register new user with Username " +
              username +
              JSON.stringify(errorInfo)
          );
        }

        return ok();
      }

      async function getNodes() {
        const nodeData = null;
        return ok({ data: nodeData });
      }

      async function getUsers() {
        const { usersInfo, error } = await supabase
          .from(dataSourceTable)
          .select("*");
        if (!isAuthenticated() || !usersInfo) return unauthorized();
        return ok(usersInfo.map((x) => basicDetails(x)));
      }

      async function getUserById() {
        if (!isAuthenticated()) return unauthorized();
        let userId = idFromUrl();
        const { error, user } = await supabase
          .from(dataSourceTable)
          .select("id")
          .eq("dataSource.id", userId);
        return ok(basicDetails(user));
      }

      async function updateUser() {
        if (!isAuthenticated()) return unauthorized();

        let params = body();
        let userId = idFromUrl();
        const { error: updateUser, user } = await supabase
          .from(dataSourceTable)
          .select("id")
          .eq("dataSource.id", userId);
        if (updateUser) {
          return error(
            "User with Id not found" +
              userId +
              "error" +
              JSON.stringify(updateUser)
          );
        }
        if (!params.password) {
          delete params.password;
        }
        if (
          params.username !== user.username &&
          users.find((x) => x.username === params.username)
        ) {
          return error('Username "' + params.username + '" is already taken');
        }

        Object.assign(user, params);
        const { insertError } = await supabase
          .from(dataSourceTable)
          .update(params)
          .eq("id", userId);
        localStorage.setItem(usersKey, JSON.stringify(users));

        return ok();
      }

      async function deleteUser() {
        if (!isAuthenticated()) return unauthorized();

        users = users.filter((x) => x.id !== idFromUrl());
        localStorage.setItem(usersKey, JSON.stringify(users));
        return ok();
      }

      async function ok(body) {
        resolve({
          ok: true,
          ...headers(),
          loading: true,
          data: () => Promise.resolve(body),
          json: () => Promise.resolve(body),
        });
      }

      async function unauthorized() {
        resolve({
          status: 401,
          ...headers(),
          json: () => Promise.resolve({ message: "Unauthorized" }),
        });
      }

      async function error(message) {
        resolve({
          status: 400,
          ...headers(),
          json: () => Promise.resolve({ message }),
        });
      }

      function basicDetails(user) {
        const { id, username, firstName, lastName } = user;
        return { id, username, firstName, lastName };
      }

      function basicDataSourceDetails(dataSource) {
        const {
          id,
          dataSourceName,
          parent,
          formats,
          data,
          url,
          raw_data,
          type,
          createdAt,
        } = dataSource;
        return {
          id,
          dataSourceName,
          parent,
          formats,
          data,
          url,
          raw_data,
          type,
          createdAt,
        };
      }

      function basicAgentDetails(dataSource) {
        const {
          id,
          name,
          description,
          formats,
          data,
          url,
          raw_data,
          type,
          createdAt,
        } = dataSource;
        return {
          id,
          name,
          description,
          formats,
          data,
          url,
          raw_data,
          type,
          createdAt,
        };
      }
      async function isAuthenticated() {
        return true;
        return opts.headers["Authorization"] === `Bearer ${supabaseJWTToken}`;
      }

      function body() {
        return opts.body && JSON.parse(opts.body);
      }

      function idFromUrl() {
        const urlParts = url.split("/");
        let id = parseInt(urlParts[urlParts.length - 1]);
        const match = url.match(/\/([^/]+)\/([^/]+)$/);
        if (match) {
          const prefix = match[1];
          id = match[match.length - 1];
          return id;
        }
        if (!isNaN(id)) {
          return id;
        } else {
          return null;
        }
      }

      function headers() {
        return {
          headers: {
            get(key) {
              return ["application/json"];
            },
          },
        };
      }

      async function getDataSources() {
        let { data: dataSource, error } = await supabase
          .from("dataSource")
          .select("*");
        if (!isAuthenticated() || !dataSource) {
          return unauthorized();
        }
        let response = dataSource.map((x) => basicDataSourceDetails(x));
        return ok({ data: response });
      }

      async function getDataSourceById() {
        if (!isAuthenticated()) return unauthorized();
        let userId = idFromUrl();
        const { error, user } = await supabase
          .from(dataSourceTable)
          .select("id")
          .eq("dataSource.id", userId);
        return ok(basicDataSourceDetails(user));
      }

      async function addDataSource() {
        if (!isAuthenticated()) return unauthorized();

        let params = body();

        const { data, error: insertError } = await supabase
          .from(dataSourceTable)
          .insert([params])
          .select();

        if (insertError) {
          return error(
            "error while inserting data into database" +
              JSON.stringify(insertError)
          );
        }
        return await getDataSources();
      }

      async function updateDataSource() {
        if (!isAuthenticated()) return unauthorized();

        let params = body();
        let userId = idFromUrl();
        const { error: updateError } = await supabase
          .from(dataSourceTable)
          .update(params)
          .eq("id", userId);

        if (updateError) {
          return error(
            "Error while updating " +
              dataSourceTable +
              " " +
              userId +
              "error" +
              JSON.stringify(updateError)
          );
        }
        return await getDataSources();
      }

      async function deleteDataSource() {
        if (!isAuthenticated()) return unauthorized();
        let dataSourceId = idFromUrl();

        const { error } = await supabase
          .from(dataSourceTable)
          .delete()
          .eq("id", dataSourceId);
        if (error) {
          return error(
            "Error while deleting " +
              dataSourceId +
              "error" +
              JSON.stringify(error)
          );
        }
        return await getDataSources();
      }

      async function getAgents() {
        let { data: dataSource, error } = await supabase
          .from(agentTable)
          .select("*");
        if (!isAuthenticated() || !dataSource) {
          return unauthorized();
        }
        let response = dataSource.map((x) => basicAgentDetails(x));
        return ok({ data: response });
      }

      async function getAgentById() {
        if (!isAuthenticated()) return unauthorized();
        let userId = idFromUrl();
        const { error, user } = await supabase
          .from(agentTable)
          .select("id")
          .eq("dataSource.id", userId);
        return ok(basicDataSourceDetails(user));
      }

      async function addAgent() {
        if (!isAuthenticated()) return unauthorized();

        let params = body();

        const { data, error: insertError } = await supabase
          .from(agentTable)
          .insert([params])
          .select();

        if (insertError) {
          return error(
            "error while inserting data into database" +
              JSON.stringify(insertError)
          );
        }
        let response = data.map((x) => basicAgentDetails(x));
        return ok({ data: response && response.length > 0 ? response[0] : {} });
      }

      async function updateAgent() {
        if (!isAuthenticated()) return unauthorized();

        let params = body();
        let userId = idFromUrl();
        const { error: updateError } = await supabase
          .from(agentTable)
          .update(params)
          .eq("id", userId);
        if (updateError) {
          return error(
            "Error while updating " +
              agentTable +
              " " +
              userId +
              "error" +
              JSON.stringify(updateError)
          );
        }
        return await getAgents();
      }

      async function deleteAgent() {
        if (!isAuthenticated()) return unauthorized();
        let dataSourceId = idFromUrl();
        const { error } = await supabase
          .from(agentTable)
          .delete()
          .eq("id", dataSourceId);
        if (error) {
          return error(
            "Error while deleting " +
              dataSourceId +
              "error" +
              JSON.stringify(error)
          );
        }
        return await getAgents();
      }
    });
  };
}
