import { alertActions } from "_store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { history, fetchWrapper } from "_helpers";
import { getsupabaseClient } from '../_helpers/supabase-backend'
const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice ? slice.reducer : null;

function createInitialState() {
	return {
		value: JSON.parse(localStorage.getItem("auth")),
	};
}

function createReducers() {
	return {
		setAuth,
	};

	function setAuth(state, action) {
		state.value = action.payload;
	}
}

function createExtraActions() {
	const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

	return {
		login: login(),
		logout: logout(),
	};

	function login() {
		return createAsyncThunk(`${name}/login`, async function ({ username, password }, { dispatch }) {
			dispatch(alertActions.clear());
			try {

				const { supabase, supabaseJWTToken } = getsupabaseClient();
				const {
					user,
					session,
					error: errorInfo,
				} = await supabase.auth.signInWithPassword({
					email: username,
					password: password,
				});

				if (!user){
					dispatch(alertActions.error(`${errorInfo && errorInfo.message ? errorInfo.message : "Username or password is incorrect"}`));
					return;
				}
				// const user = await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password });
				dispatch(authActions.setAuth(user));
				localStorage.setItem("auth", JSON.stringify(user));

				const { from } = history.location.state || { from: { pathname: "/" } };
				history.navigate(from);
			} catch (error) {
				dispatch(alertActions.error(error));
			}
		});
	}

	function logout() {
        return createAsyncThunk(`${name}/logout`, async function (arg, { dispatch }) {
            // console.log("Logout clicked ");
            // await fetchWrapper.post(`${baseUrl}/logout`, { arg });
            const { supabase, supabaseJWTToken } = getsupabaseClient();
            const { error: errorInfo } = await supabase.auth.signOut();
            console.log("errorInfo ", errorInfo);
            if (errorInfo) {
                return dispatch(alertActions.error("Error while register new user wuth Username" + JSON.stringify(errorInfo)));
            }
            dispatch(authActions.setAuth(null));
            localStorage.removeItem("auth");
            if (history && history.navigate) {
                history.navigate("/account/login");
            }
            localStorage.setItem("showLoader", false);
        });
    }


	async function forgotPassword() {
		try {
			await fetchWrapper.post(`${baseUrl}/forgotPassword`);
			if (history && history.navigate) {
				history.navigate("/account/login");
			}
		} catch (error) {
			console.log("error", error);
		}
	}
}
