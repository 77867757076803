import './assets/fonts/RFDewi-Black/style.css';
import './assets/fonts/RFDewi_Extended_Semibold/style.css';
import './assets/fonts/SFProDisplay/style.css';
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { history } from "_helpers";
import "./assets/scss/style.scss";
import Routes from "./routes";
export { App };

function App() {

  history.navigate = useNavigate();
  history.location = useLocation();

  return (
      <div className="app-container bg-light">
        <div className="container pt-4 pb-4">
          <Routes>
          </Routes>
        </div>
      </div>
  );
}
