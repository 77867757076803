import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { SET_MENU } from "../../_store/actions";
import Sidebar from "./Sidebar";
import { drawerWidth } from "../../_helpers/constant";
import { IconMenu2 } from "@tabler/icons-react";
import { Triangle } from "react-loader-spinner";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% )`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `calc(100%)`,
        padding: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        width: `calc(100% )`,
        padding: "16px",
        marginRight: "10px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100vw - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        width: `calc(100vw - ${drawerWidth}px)`,
        marginLeft: "230px",
      },
      [theme.breakpoints.down("sm")]: {
        width: `calc(100vw - ${drawerWidth}px)`,
        marginLeft: "230px",
      },
      minWidth: `500px`,
    }),
  })
);

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [userEmail, setUserEmail] = useState("");
  const leftDrawerOpened = useSelector((state) =>
    state && state.customization && state.customization.opened !== undefined
      ? state.customization.opened
      : false
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let showLogoutLoader = localStorage.getItem("showLogoutLoader");
  if (showLogoutLoader === undefined || showLogoutLoader === null) {
    showLogoutLoader = false;
    localStorage.setItem("showLogoutLoader", false);
  }
  const [logoutLoader, setLogoutLoader] = React.useState(
    showLogoutLoader === "true" ? true : false
  );

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("showLogoutLoader", false);
      setLogoutLoader(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const authTokenObject = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_STORAGE_KEY)
    );
    if (
      authTokenObject &&
      authTokenObject.user.user_metadata.email &&
      authTokenObject.user.user_metadata.name
    ) {
      const userEmail = authTokenObject.user.user_metadata.email;
      setUserEmail(userEmail);
    } else {
      navigate("/account/login");
    }
  }, []);

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    setTimeout(() => dispatch({ type: SET_MENU, opened: !matchDownMd }), 0);
  }, [matchDownMd]);

  return (
    <>
      {logoutLoader ? (
        <Box
          sx={{
            display: "flex",
            minHeight: "94dvh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Triangle
              height="100"
              width="100"
              color="#6C5CE7"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={logoutLoader}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          {userEmail !== "" && (
            <>
              <CssBaseline />
              <Sidebar
                setLogoutLoader={setLogoutLoader}
                drawerOpen={leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
              />
              <Main theme={theme} open={true}>
                <Outlet />
              </Main>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default MainLayout;
