import { experimental_extendTheme as materialExtendTheme, Experimental_CssVarsProvider as MaterialCssVarsProvider, THEME_ID as MATERIAL_THEME_ID } from "@mui/material/styles";
import colors from "../assets/scss/_themes-vars.module.scss";
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

export const theme = (customization, mode) => {
  const color = colors;

  const themeOption = mode
    ? {
        colors: color,
        heading: color.paper,
        paper: color.darkPrimaryLight,
        backgroundDefault: color.darkPaper,
        background: color.darkPrimaryLight,
        darkTextPrimary: color.paper,
        darkTextSecondary: color.paper,
        textDark: color.paper,
        menuSelected: color.darkSecondaryDark,
        menuSelectedBack: color.darkSecondaryLight,
        divider: color.darkPaper,
        customization,
        sujalStyleColor: "#FF0000",
        testParams: "DarkTest",
      }
    : {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryLight,
        divider: color.grey200,
        customization,
        sujalStyleColor: "#00FF00",
        testParams: "lightTest",
      };

  const themeOptions = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography(themeOption),
  };
  const themes = materialExtendTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;