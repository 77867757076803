import chatClient from "./chatClient";

const createUser = (body) => chatClient.post(`/user`, body)
const getUser = (body) =>chatClient.get(`/user?email_id=${body.email ? body.email : ""}`);
const getUnitCost = (id) => chatClient.get(`/user/${id}`);

export default {
    createUser,
    getUser,
    getUnitCost
}