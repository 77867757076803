import { Typography } from "@mui/joy";
import { useTheme, useThemeProps } from "@mui/material";
import React from "react";

import { useSelector } from "react-redux";

const logo = "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png";
const logoDark = logo;

const Logo = () => {
  const customization = useSelector((state) => state.customization);
  const theme = useTheme();
  return (
    <div style={{ alignItems: "center", display: "flex", flexDirection: "row", color: '#C6CBFD', paddingTop: 15, paddingBottom: 5 }}>
      <Typography style={theme.typography.Heading3} fontWeight={700} color='#C6CBFD'>Brilo</Typography>
    </div>
  );
};

export default Logo;
