import React from "react";
import { store, persistor } from "./_store";
import { ReactFlowContext } from "./_store/context/ReactFlowContext";
import { StyledEngineProvider } from "@mui/joy/styles";
import { createRoot } from "react-dom/client";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import NavigationScroll from "./layout/NavigationScroll";
import { useTheme } from "@mui/material/styles";
import themes from "./themes";
import ConfirmContextProvider from "./_store/context/ConfirmContextProvider";
import { App } from "./App";
import "./index.css";
import { SnackbarProvider } from "notistack";
import { initSupabase } from "./_helpers";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import { PersistGate } from 'redux-persist/integration/react';

initSupabase();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <SnackbarProvider>
          <ConfirmContextProvider>
            <ReactFlowContext>
              <AppUI />
            </ReactFlowContext>
          </ConfirmContextProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </StyledEngineProvider>
    </PersistGate>
  </Provider>
);

function AppUI() {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  return (
    <MaterialCssVarsProvider
      theme={{ [MATERIAL_THEME_ID]: themes(customization) }}
    >
      <JoyCssVarsProvider defaultMode="light">
        <NavigationScroll>
          <App />
        </NavigationScroll>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
