import { Suspense } from 'react'

import Loader from './Loader'

const Loadable = (Component) =>
    function WithLoader(props) {
        return (
            <Suspense fallback={<Loader />}>
                <Component {...props} />
            </Suspense>
        )
    }

export default Loadable
