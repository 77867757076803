import { useRoutes } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import ChatbotRoutes from "./ChatbotRoutes";
import PreLoginRoutes from "./PreLoginRoutes";
import config from "config";

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, ChatbotRoutes, PreLoginRoutes], config.basename);
}

